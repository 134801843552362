import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import templogo from "../Welli_Logo-06.jpg";
import logoInicial from "../logoInicial.jpg";
import { useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import RejectModal from "./RejectModal";
import GenericModal from "./GenericModal";
import { clearSteps } from "../hooks/useApplicationSteps";
import store from "../store";
import { setAuth } from "../store/auth";
import { InputAdornment } from "@mui/material";
import { useForm } from "react-hook-form";
import BodyRejectedComponent from "../Shared/BodyRejectedComponent";
import { ACCESS_DASHBOARD_ENABLED, availableAccessDashboardStatus, unavailableAnyAccess, validatingAPPStatus } from "../core/enums/appStatus";
import Copyright from "../Shared/Copyright";


const theme = createTheme();

export default function SignInSide() {
  const [otp, setOtp] = useState();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showRejectedModal, setShowRejectedModal] = useState(false);
  const [showFailAuth, setShowFailAuth] = useState(false);
  const [showFailCods, setShowFailCods] = useState(false);
  const [showSendCods, setShowSendCods] = useState(false);
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function eventoCajaTexto(newValue) {
    setOtp(newValue);
  }

  const validatePhone = ({ phone }) => {
    axios
      .get(
        `${process.env.REACT_APP_WELLI_API}/api/accounts/login/phone/57${phone}`
      )
      .then(() => {
        setShowSendCods(true);
      })
      .catch((error) => {
        console.log("error", error);
        setShowFailAuth(true);
      });
  };
  const sentToNavigate = (token, url, user) => {
    sessionStorage.setItem("user", JSON.stringify(user));
    clearSteps();
    store.dispatch(setAuth({ user, token, status: "authenticated" }));
    navigate(url);
  };
  const validarOtp = (event) => {
    event.preventDefault();

    const body = {
      otp: otp,
      platform: "sms",
      value: "57" + getValues("phone"),
    };

    axios
      .post(`${process.env.REACT_APP_WELLI_API}/api/accounts/login`, body)
      .then(function (resp) {
        const { data } = resp;
        sessionStorage.setItem("id", data.id);
        sessionStorage.setItem("token", data.token);
        sessionStorage.setItem("profile_id", data.profile_id);

        if (data.profile_id == null) {
          sentToNavigate(data.token, "/profileform", null);
          return;
        }

        const user = {
          telefono: data.telefono,
          email: data.email,
          nombres: data.nombres,
          apellidos: data.apellidos,
        };

        if (!data?.estado_app) {
          sentToNavigate(data.token, "/profileform", user);
          return;
        }

        const estado = data?.estado_app;
        const credito = data?.credito;

        if (credito && availableAccessDashboardStatus.includes(estado)) {
          if (ACCESS_DASHBOARD_ENABLED) {
            sessionStorage.setItem("loan", credito);
            sentToNavigate(data.token, "/dashboard", user);
          }else{
            setShowModal(true);
          }
          return;
        }

        if (validatingAPPStatus.includes(estado)) {
          setShowModal(true);
          return;
        }

        if (unavailableAnyAccess.includes(estado)) {
          setShowRejectedModal(true);
          return;
        }
        sentToNavigate(data.token, "/profileform", user);
      })
      .catch(function (error) {
        // handle error
        setShowFailCods(true);
        console.log(error);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <RejectModal
        show={showModal}
        logout={() => {
          setShowModal(false);
        }}
      ></RejectModal>
      <GenericModal
        show={showRejectedModal}
        title="No pudimos aprobar tu crédito."
        description=""
        confirm={() => {
          setShowRejectedModal(false);
        }}
        textButton="Cerrar"
        bodyComponent={<BodyRejectedComponent />}
      ></GenericModal>
      <GenericModal
        show={showFailAuth}
        textButton="Cerrar"
        title=""
        bodyComponent={
          <Typography variant="h6" component="h2">
            El celular aún no se ha registrado. Primero debes{" "}
            <a
              onClick={() => {
                navigate("/create_account");
              }}
              href="#"
            >
              crear una cuenta.{" "}
            </a>
          </Typography>
        }
        confirm={() => {
          setShowFailAuth(false);
        }}
      ></GenericModal>
      <GenericModal
        show={showFailCods}
        textButton="Cerrar"
        title="Por favor revisa los códigos envíados e intenta nuevamente."
        confirm={() => {
          setShowFailCods(false);
        }}
      ></GenericModal>
      <GenericModal
        show={showSendCods}
        textButton="Cerrar"
        title="El código ha sido enviado al correo electrónico y celular registrado"
        confirm={() => {
          setShowSendCods(false);
        }}
      ></GenericModal>

      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={5}
          md={8}
          lg={9}
          sx={{
            backgroundImage: "url(" + logoInicial + ")",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "left",
          }}
        />
        <Grid
          item
          xs={12}
          sm={7}
          md={4}
          lg={3}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img alt="Welli" src={templogo} width="105"/>
            <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
              INICIO SESION
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                mt: 1,
                width: "100%",
              }}
            >
              <TextField
                name="phone"
                autoComplete="celular"
                fullWidth
                label="Celular"
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+57</InputAdornment>
                  ),
                }}
                {...register("phone", {
                  required: "El número de celular es requerido",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Ingresa datos numéricos",
                  },
                  maxLength: {
                    value: 10,
                    message:
                      "El número de celular no debe ser mayor de 10 caracteres",
                  },
                  minLength: {
                    value: 10,
                    message:
                      "El número de celular no debe ser menor de 10 caracteres",
                  },
                })}
                error={Boolean(errors.phone)}
                helperText={errors.phone?.message}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit(validatePhone)}
              >
                Solicitar código
              </Button>
            </Box>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                mt: 1,
                width: "100%",
              }}
            >
              <Grid>
                <MuiOtpInput
                  length={4}
                  value={otp}
                  onChange={eventoCajaTexto}
                />
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={validarOtp}
              >
                Ingresar a Welli
              </Button>
            </Box>
            <Grid container sx={{ mb: 2 }}>
              <Grid item xs>
                <Link
                  to="/create_account"
                  variant="body2"
                  onClick={() => clearSteps()}
                >
                  ¿No tienes una Cuenta? Créala
                </Link>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Copyright sx={{ mt: 40 }} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
