


export const isDateValid = (date) => {
    // Verificar que la fecha esté en el formato adecuado (mm/dd/aaaa)
    
    const selectedDate = new Date(date);
    const day = selectedDate.getDate();
    const month = selectedDate.getMonth() + 1;
    const year = selectedDate.getFullYear();
 
    const dateObject = new Date(`${year}-${month}-${day}`);
    if (isNaN(dateObject.getTime())) {
      return false;
    }
    return true;
};



export const getDiffYearsCurrentDate = (date) => {
  const birthDate = new Date(date);
  const currentDate = new Date();
  let diffYears = currentDate.getFullYear() - birthDate.getFullYear();

  if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
  ) {
      diffYears--;
  }

  return { diffYears };
    
};